<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/extras">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-2"
                label="Category Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="category_name"
                  type="text"
                  readonly=""
                  required
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Extra Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="extra_name"
                  type="text"
                  placeholder="Enter Extra"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Extra Price *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="price"
                  type="text"
                  placeholder="Enter Price"
                ></b-form-input>
              </b-form-group>

              <div style="width:10%;padding-left:10px">
                <a class="btn btn-info" @click="appendRow">Add</a>
              </div>
              <!-- <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              > -->
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteDeliveryAddress="deleteDeliveryAddress"
            @closeModal="closeModal"
          />
        </template>
        <div class="card">
          <template>
            <ExtrasView
              :deliveryAddress="deliveryAddressArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>
          <div class="col-sm-2 col-md-2">
            <b-button class="btn-info" ref="save" type="submit">Save</b-button>
          </div>
          <br />
        </div>
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import ExtrasView from "@/view/pages/Extras/ExtrasView";
import axios from "axios";
export default {
  components: {
    VerficationModal,
    ExtrasView,
  },
  data() {
    return {
      deliveryAddressArray: [],
      tempDeletedDeliveryAddress: [],
      tempArrayDelte: [],
      deliveryAddresstempArray: "",
      headerTitle: "Edit Extras",
      extra_name: "",
      currentAddressname: "",
      category_name: "",
      id: "",
      selected: null,
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      price: "",
      show: true,
    };
  },

  mounted() {
    document.title = "Add Customer";
    if (this.$router.history.current.path == "/editextras") {
      document.title = "Edit Extras";
      this.headerTitle = "Edit Extras";
      this.editDataApppend();
    }
    for (let ref in this.$refs) {
      this.$refs[ref].focus();
    }
  },
  methods: {
    editDataApppend() {
      this.editcustomer = JSON.parse(localStorage.getItem("editextras"));
      this.category_name = this.editcustomer.name;
      this.id = this.editcustomer.id;
      this.deliveryAddressArray = this.editcustomer.extras;
      this.deliveryAddressView = true;
    },

    appendRow() {
      if (this.extra_name == "") {
        this.toasterMessageError("Extra Name Required");
        return false;
      }
      if (this.price == "") {
        this.toasterMessageError("Extra Price Required");
        return false;
      }
      let data = {
        name: this.extra_name,
        price: this.price,
      };
      this.deliveryAddressArray.push(data);
      this.toasterMessageSuccess(
        "Record added successfully, please check below"
      );
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
      this.extra_name = "";
      this.price = "";
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    toasterMessageError(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteDeliveryAddress(object) {
      this.tempDeletedDeliveryAddress.push(object.item.id);
      let index = this.deliveryAddressArray.findIndex((x) => x === object.item);
      this.deliveryAddressArray.splice(index, 1);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        let data = {
          id: this.id,
          extras: JSON.stringify(this.deliveryAddressArray),
        };
        evt.preventDefault();

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/extras/store", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "extras" });
            }
            if (response.data.code == 422) {
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
